export function arrayChunk ({ inputArr, chunkLength, trimEnd, placeholder }) {
  const chunkNumber = chunkLength ? Math.ceil(inputArr.length / chunkLength) : 0;
  return Array(chunkNumber).fill().map((_, idx) => {
    const a = inputArr.slice(idx * chunkLength, (idx + 1) * chunkLength);
    const diff = chunkLength - a.length;
    if (!trimEnd && diff) {
      if (typeof a === 'string') {
        return a + Array(diff).fill(placeholder || ' ').join();
      } else {
        return [...a, ...Array(diff).fill(placeholder || undefined)];
      }
    } else {
      return a;
    }
  });
}
