<template>
  <div v-if="screenRange['tablet-min']" :class="$style.tabHeaderItems">
    <div ref="referenceElement" :class="[$style.wrapper, $style[`wrapper-${tabType}`]]">
      <div :class="[$style.header, $style[`header-${tabType}`]]">
        <div
          :class="[$style.selectedBackground, $style[`selectedBackground-${tabType}`]]"
          :style="{width: `${selectedTabItem.width}px`, left: `${selectedTabItem.left}px` }"
        />
        <lv-button
          v-for="(item, index) in visibleHeaderItems"
          :id="`aw-${awTabUuid}-tab-${index}`"
          ref="tabItem"
          :key="item.label"
          stretch
          :styling="item.to ? 'link-primary-version2' : null"
          :aria-selected="selectedTabIndex === index"
          :aria-controls="`aw-${awTabUuid}-tabpanel-${index}`"
          :class="[
            $style.headerItem,
            {[$style.headerItemSelected]: index === selectedTabIndex}
          ]"
          :tabindex="selectedTabIndex === index ? null : '-1'"
          :type="item.to ? null : 'button'"
          role="tab"
          :to="item.to || null"
          @click="selectTabItem(item, true)"
          @keydown="keyDown($event, index)"
        >
          <span ref="headerItem" :class="$style.headerItemText">
            {{ item.label }}
          </span>
          <span :class="[$style.line, {[$style.lineSelected]: selectedTabIndex === index}]" />
        </lv-button>
        <lv-tooltip
          v-if="tooltipItems.length > 0"
          :key="`tooltip-secondary-version2`"
          v-model:show-tooltip="showTooltip"
          is-false-tooltip
          :button-attrs="{
            tabIndex: '-1',
            ariaHidden: true,
          }"
          trigger="click"
          styling="secondary-version2"
          :options="{
            placement: 'bottom-end',
            modifiers: {
              offset: {
                offset: '0,10'
              }
            }
          }"
        >
          <template #tooltip>
            <div data-popover-item-wrapper :class="$style.tooltipList">
              <lv-button
                v-for="(item, index) in tooltipItems"
                :id="`aw-${awTabUuid}-tab-${tooltipIndex(index)}`"
                ref="tooltipItem"
                :key="item.label"
                stretch
                :styling="item.to ? 'link-primary-version2' : null"
                :class="['buttonReset', $style.headerItem]"
                data-popover-item
                :data-popover-item-selected="isActiveTooltipItem(index)"
                :aria-selected="selectedTabIndex === tooltipIndex(index)"
                :aria-controls="`aw-${awTabUuid}-tabpanel-${tooltipIndex(index)}`"
                :tabindex="selectedTabIndex === tooltipIndex(index) ? null : '-1'"
                :type="item.to ? null : 'button'"
                role="tab"
                :to="item.to || null"
                @click="selectTabItem(item, true)"
                @keydown="keyDown($event, tooltipIndex(index))"
              >
                <span :class="$style.headerItemText">
                  {{ item.label }}
                </span>
              </lv-button>
            </div>
          </template>
          <template #reference>
            <span
              id="headerMoreItem"
              :class="[ $style.headerItem, $style.headerItemMoreItems ]"
              @click="showTooltip = !showTooltip"
            >
              <span>
                {{ $awt('aw.common.tab.header.more_items') }}
              </span>
              <lv-icon name="arrow-down-16" :size="16" :class="$style.headerItemMoreItemsIcon" />
            </span>
          </template>
        </lv-tooltip>
      </div>
    </div>
    <div
      ref="hiddenHeader"
      :aria-hidden="true"
      :class="[$style.header, $style[`header-${tabType}`], $style.hiddenHeader]"
    >
      <div ref="hiddenMoreItem" :class="[ $style.headerItem, $style.headerItemMoreItems ]">
        <span>
          {{ $awt('aw.common.tab.header.more_items') }}
        </span>
        <lv-icon name="arrow-down-16" :size="16" :class="$style.headerItemMoreItemsIcon" />
      </div>
      <div
        v-for="(item, index) in items"
        ref="hiddenItem"
        :key="item.label"
        :class="[
          $style.headerItem,
          {[$style.headerItemSelected]: index === selectedTabIndex}
        ]"
      >
        <span>
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
  <div v-else>
    <aw-select-version2
      v-if="selectedTab"
      v-model:selected-options="selectedTab"
      :options="items"
      tabindex="0"
      option-id-property="label"
      option-label-property="label"
      :multiple="false"
      :dropdown-icon-attrs="{
        name: 'arrow-down-16',
        size: 16
      }"
      :class="$style.dropdown"
      @update:selected-options="selectTabItem"
    />
  </div>
</template>

<script setup>

  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import { computed, defineAsyncComponent, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useTabStore } from '~~/common/stores/tab.js';
  import { navigateTo, useNuxtApp } from 'nuxt/app';

  import { LvButton, LvIcon, LvTooltip } from '~~/common/components/loginet-vue-shop/index.mjs';

  const AwSelectVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwSelectVersion2'));

  const tabStore = useTabStore();
  const {
    $router,
    $localePath,
    $awEvents,
  } = useNuxtApp();

  const props = defineProps({
    items: {
      type: Array,
      required: true,
    },
    tabType: {
      type: String,
      required: true,
      validator: type => ['page', 'container'].includes(type),
    },
  });

  const emit = defineEmits(['select-tab-item']);

  const awTabUuid = inject('uuid');
  const tabItem = ref(null);
  const tooltipItem = ref(null);
  const selectedTab = ref(null);
  const headerItem = ref(null);
  const hiddenItem = ref(null);
  const hiddenHeader = ref(null);
  const hiddenHeaderWidth = ref(0);
  const hiddenMoreItemWidth = ref(0);
  const visibleIndex = ref(null);
  const referenceElementWidth = ref(0);
  const referenceElement = ref(null);

  const hiddenMoreItem = ref(null);
  const selectedTabItem = ref({
    left: 0,
    width: 0,
  });

  const showTooltip = ref(false);

  const tabs = computed(() => {
    return [...(tabItem.value ?? []), ...(tooltipItem.value ?? [])];
  });

  const screenRange = computed(() => {
    return useUserInterfaceStore().mediaQueries;
  });

  const selectedTabIndex = computed(() => {
    return props.items.findIndex(item => {
      if (item.to) {
        return $router.currentRoute.value.fullPath === item.to || item.alternativeRoutePaths?.includes($router.currentRoute.value.fullPath);
      } else {
        return tabStore.tabs[awTabUuid.value] === item.tabItemId;
      }
    });
  });

  const visibleHeaderItems = computed(() => {
    return visibleIndex.value != null ? props.items.slice(0, visibleIndex.value) : props.items;
  });

  const tooltipItems = computed(() => {
    return visibleIndex.value != null ? props.items.slice(visibleIndex.value) : [];
  });

  onMounted(() => {
    /* setTimeout is here so this runs later than the page transition */
    setTimeout(() => {
      selectedTab.value = props.items[selectedTabIndex.value];
      selectTabItem(selectedTab.value, true);

      if (screenRange.value['tablet-min']) {
        calculateWidth();
      }
    }, 320);
    $awEvents.addEvent('throttled-resize', calculateWidth);
  });

  onUnmounted(() => {
    $awEvents.removeEvent('throttled-resize', calculateWidth);
  });

  function setSelectedTabItemBackground () {
    let left = 0;
    const gap = props.tabType === 'container' ? 16 : 24;

    if (visibleIndex.value == null || selectedTabIndex.value < visibleIndex.value) {

      const clientRect = headerItem.value?.[selectedTabIndex.value]?.getBoundingClientRect();
      selectedTabItem.value.width = clientRect?.width + gap;

      headerItem.value?.forEach((item, index) => {
        if (index < selectedTabIndex.value) {
          left += item.getBoundingClientRect().width + gap + gap;
        } else if (index === selectedTabIndex.value) {
          left += props.tabType === 'container' ? 8 : 12;
        }
      });

    } else {
      const headerMoreItem = document.getElementById('headerMoreItem');
      const clientRect = headerMoreItem?.getBoundingClientRect();
      selectedTabItem.value.width = clientRect?.width;
      headerItem.value?.forEach((item) => {
        left += item.getBoundingClientRect().width + gap + gap;
      });
      left += props.tabType === 'container' ? 8 : 12;
    }

    selectedTabItem.value.left = left;
  }

  function calculateWidth () {
    const gap = props.tabType === 'container' ? 16 : 24;

    referenceElementWidth.value = referenceElement.value?.getBoundingClientRect().width;
    const hiddenHeaderChildren = hiddenHeader.value?.children;
    hiddenHeaderWidth.value = 0;
    let childIndex = 0;
    for (const hiddenChild of hiddenHeaderChildren ?? []) {
      hiddenHeaderWidth.value += hiddenChild.getBoundingClientRect().width + (childIndex < hiddenHeaderChildren.length - 1 ? gap : 0);
      childIndex++;
    }

    hiddenMoreItemWidth.value = hiddenMoreItem.value?.getBoundingClientRect().width;

    if (referenceElementWidth.value < (hiddenHeaderWidth.value - hiddenMoreItemWidth.value - gap)) {
      let actualWidth = hiddenMoreItemWidth.value;
      let index = 0;

      while ((actualWidth + hiddenItem.value[index]?.getBoundingClientRect()?.width + gap) < referenceElementWidth.value) {
        const clientRect = hiddenItem.value[index]?.getBoundingClientRect();
        actualWidth += clientRect?.width + gap;
        index++;
      }
      visibleIndex.value = index;
    } else {
      //reset VisibleIndex, all header items fit in the header
      visibleIndex.value = null;
    }
    nextTick(() => {
      setSelectedTabItemBackground();
    });
  }

  function isActiveTooltipItem (index) {
    return selectedTabIndex.value === tooltipIndex(index);
  }

  function tooltipIndex (index) {
    return index + visibleHeaderItems.value.length;
  }

  function keyDown (event, index) {
    let flag = false;
    switch (event.key) {
    case 'ArrowLeft':
      setSelectedToPreviousTab(index);
      flag = true;
      break;

    case 'ArrowRight':
      setSelectedToNextTab(index);
      flag = true;
      break;

    case 'Home':
      setSelectedTab(0);
      flag = true;
      break;

    case 'End':
      setSelectedTab(tabs.value.length - 1);
      flag = true;
      break;

    default:
      break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function setSelectedToNextTab (index) {
    if (index === tabs.value.length - 1) {
      setSelectedTab(0);
    } else {
      setSelectedTab(index + 1);
    }
  }

  function setSelectedToPreviousTab (index) {
    if (index === 0) {
      setSelectedTab(tabs.value.length - 1);
    } else {
      setSelectedTab(index - 1);
    }
  }

  function setSelectedTab (index) {
    if (index >= visibleIndex.value && !showTooltip.value) {
      showTooltip.value = true;
    }
    if (index < visibleIndex.value && showTooltip.value) {
      showTooltip.value = false;
    }
    nextTick(() => {
      tabs.value[index].$el.focus();
    });
  }

  function selectTabItem (selectedOptions, desktop) {
    if (selectedOptions && !selectedOptions.to) {
      tabStore.setTab({
        uuid: awTabUuid.value,
        index: selectedOptions.tabItemId,
      });
    }
    if (!desktop && selectedOptions?.to) {
      navigateTo($localePath(selectedOptions.to));
    }
    if (selectedOptions) {
      nextTick(() => {
        emit('select-tab-item', {
          uuid: awTabUuid.value,
          index: selectedOptions.tabItemId,
        });
      });
    }

  }

  watch(selectedTabIndex, () => {
    if (screenRange.value['tablet-min']) {
      setSelectedTabItemBackground();
    }
  });
  watch(visibleIndex, () => {
    if (screenRange.value['tablet-min']) {
      setSelectedTabItemBackground();
    }
  });

  watch(() => props.items, () => {
    nextTick(() => {
      calculateWidth();
    });
  });

</script>

<style module lang="scss" rel="stylesheet/scss">

$aw-tab-header-item-animation: cubic-bezier(0.4, 0, 0.2, 1);
$aw-tab-header-item-animation-duration: 0.6s;

.tabHeaderItems {
  display: contents !important;
}

.wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;

  &-page {
    justify-content: center;
  }
}

.header {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  border: 1px solid $color-border-disabled;
  background: $color-background-3;
  gap: 24px;

  &:not(.hiddenHeader) {
    max-width: 100%;
  }

  &Item {
    position: relative;
    width: unset !important;
    cursor: pointer;
    transition: all $aw-tab-header-item-animation-duration $aw-tab-header-item-animation;
    text-align: center;
    text-wrap: nowrap;
    color: $color-text-secondary;
    border: none;
    background: transparent;
    @include font($font-weight-bold-v2, 16px, 24px);

    &Text {
      display: block;
      height: 20px;
    }

    &Selected {
      color: $color-text-primary;
    }

    &MoreItems {
      display: flex;
      gap: 4px;
      align-items: center;

      &Icon {
        color: $color-text-primary;
      }
    }

    .line {
      position: absolute;
      bottom: 6px;
      left: 50%;
      width: 1px;
      height: 2px;
      transition: all $aw-tab-header-item-animation-duration $aw-tab-header-item-animation;
      transform-origin: center;
      opacity: 0;
      border: 0 solid $color-text-tertiary;
      border-radius: 4px;
      background: $color-text-tertiary;
    }

    &:focus {
      text-decoration: none;

    }

    &:focus, &:active {
      text-decoration: none !important;
    }

    &:hover {
      text-decoration: none !important;
      color: $color-text-primary;

      .line {
        &:not(.lineSelected) {
          transform: scaleX(24);
          opacity: 1;
          background: $color-text-primary;
        }
      }
    }

    &:active {
      .line {
        &:not(.lineSelected) {
          transform: scaleX(8);
          opacity: 1;
          background: $color-text-tertiary;
        }
      }
    }
  }


  &-page {
    padding: 11px;
    /*page level tab*/
    border-radius: 24px;

    .headerItem {
      height: 40px;
      padding: 8px 12px;

      &Text {
        height: 24px;
      }
    }

    .selectedBackground {
      height: 40px;
    }
  }

  &-container {
    padding: 7px;
    /*container level tab*/
    border-radius: 16px;
    gap: 16px;

    .headerItem {
      height: 28px;
      padding: 4px 8px;
      @include font($font-weight-bold-v2, 14px, 20px);

      .line {
        bottom: 2px;
      }
    }

    .selectedBackground {
      height: 28px;
    }
  }
}

.selectedBackground {
  position: absolute;
  top: 1px;
  transition: all $aw-tab-header-item-animation-duration $aw-tab-header-item-animation;
  background: $color-background-4;
  box-shadow: 0 2px 8px 0 $color-olive-750--8;

  &-page {
    height: 40px;
    margin: 11px 0;
    padding: 8px 12px;
    border-radius: 12px;
  }

  &-container {
    height: 28px;
    margin: 7px 0;
    padding: 4px 12px;
    border-radius: 8px;
  }
}

.hiddenHeader {
  position: absolute;
  top: -125%;
  visibility: hidden;
  overflow: hidden;
  width: 0;
}

.dropdown {
  z-index: 10000;
  width: 100%;

  [data-form-input-select] {
    display: grid;
    height: 52px;
    grid-template-columns: auto 24px;
  }

  [data-form-input-select-title] {
    display: block !important;
    overflow: hidden;
    width: 100%;
    height: unset !important;
    text-overflow: ellipsis;
  }
}

.tooltip {
  &List {
    display: flex;
    flex-direction: column;

    .headerItem {
      font-weight: $font-weight-normal-v2;
      height: auto;
      text-align: left;
      text-wrap: unset;

      &Text {
        height: unset;
      }
    }
  }
}

</style>
