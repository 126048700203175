import { defineStore } from 'pinia';
import { useNuxtApp } from 'nuxt/app';
import { ref } from 'vue';
import ObjectCache from '~~/common/utils/ObjectCache.js';
import otqs from '~~/common/utils/objectToQueryString';

const faqCache = new ObjectCache('faq', '__');
export const useFaqsStore = defineStore('faqs', () => {
  const nuxtApp = useNuxtApp();
  const items = ref([]);

  async function fetchFaqs (options) {
    const {
      $api,
      $logger,
      $i18n,
    } = nuxtApp;
    const cacheKey = ['faq', $i18n.locale.value, options?.isPetrol, options?.isLoyaltyProgram];

    let params = {
      categoryId: 1,
      page: 1,
      isPetrol: options?.isPetrol ? 1 : 0,
      isLoyaltyProgram: options?.isLoyaltyProgram ? 1 : 0,
    };

    faqCache.initCache(cacheKey, () => $api.$get(`faq-list${otqs(params)}`));
    const result = await faqCache.getCache(cacheKey).value.catch((err) => {
      $logger.error(err);
      return [];
    });
    const resultArray = Object.values(result);
    items.value = await [...resultArray].sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1);
  }

  return {
    items,
    fetchFaqs,
  };
});
