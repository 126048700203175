export default {
  methods: {
    sectionView (id) {
      window.history.replaceState(null, null, `#${id}`);
      this.calculateActiveSection();
    },
    isActiveSection (id) {
      return this.activeSection === `#${id}`;
    },
    calculateActiveSection () {
      this.activeSection = window?.location?.hash;
    },
  },
  mounted () {
    this.sectionWatcherInterval = setInterval(() => {
      this.calculateActiveSection();
    }, 100);
  },
  beforeUnmount () {
    clearInterval(this.sectionWatcherInterval);
  },
  data () {
    return {
      activeSection: null,
      sectionWatcherInterval: null,
    };
  },
};
