<template>
  <aw-cms-page-sidebar-wrapper :menu-items="menuItems" :selected-item="currentItem">
    <template #content>
      <slot />
    </template>
  </aw-cms-page-sidebar-wrapper>
</template>

<script setup>
  import { computed, onMounted } from 'vue';
  import { useContentMenusStore } from '~~/common/stores/contentMenus.js';
  import { removeLocaleFromUrl } from '~~/common/utils/urlTransformation.js';
  import AwCmsPageSidebarWrapper from '~~/common/components/Page/Content/Wrapper/Sidebar/AwCmsPageSidebarWrapper.vue';
  import { useRoute } from 'nuxt/app';

  const contentMenusStore = useContentMenusStore();

  onMounted(async () => {
    await contentMenusStore.initMenu('loyalty-program-menu');
  });

  const loyaltyProgramMenuItems = computed(() => contentMenusStore.loyaltyProgramMenu);

  const menuItems = computed(() => {
    return [
      ...loyaltyProgramMenuItems.value?.map((item, index) => ({
        id: `${item.to}-${index}`,
        name: item.label,
        link: item.to,
      })) || []];
  });

  const currentItem = computed(() => menuItems.value?.find(item => isActive(item)));

  function isActive (menuItem) {
    const route = useRoute();
    return removeFirstSlash(removeTrailingSlash(removeLocaleFromUrl(route.path))) === removeFirstSlash(removeTrailingSlash(removeTrailingSlash(menuItem.link)));
  }

  function removeFirstSlash (url) {
    return url.replace(/^\/+/, '');
  }

  function removeTrailingSlash (url) {
    return url.replace(/\/$/, '');
  }
</script>
