<template>
  <section ref="section" :class="[$style.section, `section-${sectionId}`]">
    <div :id="sectionId" :class="$style.sectionAnchor1" />
    <slot />
  </section>
</template>

<script>
  import { onMounted, onUnmounted } from 'vue';

  export default {
    name: 'AwScrollSection',
    props: {
      sectionId: {
        type: String,
        required: true,
      },
    },
    emits: ['section-view'],
    setup (props, { emit }) {
      const handleSectionInView = () => {
        emit('section-view', props.sectionId);
      };

      const observeSections = () => {
        const observer = new IntersectionObserver((entries) => {
          const intersectingSections = entries.find(entry => entry.isIntersecting && entry.target.classList.contains(`section-${props.sectionId}`));
          if (intersectingSections) {
            handleSectionInView();
          }
        });

        const section = document.querySelector(`.section-${props.sectionId}`);
        if (section) {
          observer.observe(section);
        }

        return observer;
      };

      let observer = null;

      onMounted(() => {
        if (import.meta.client) {
          observer = observeSections();
        }
      });

      onUnmounted(() => {
        if (observer) {
          observer?.disconnect();
        }
      });
    },
  };

</script>

<style module lang="scss" rel="stylesheet/scss">
.section {
  position: relative;

  &Anchor {
    &1 {
      position: absolute;
      top: -200px;
      @include desktop-small(min) {
        top: -100px;
      }
    }
  }
}
</style>
