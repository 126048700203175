<template>
  <div :class="$style.tab" role="tablist">
    <div
      :class="[ $style.tabHeader, $style[`tabHeader-${tabType}`], $style[`tabHeaderFlex-${headerFlexType}`]]"
      style="padding-right: var(--awTabHeadingRight, 0);padding-left: var(--awTabHeadingLeft, 0);gap: var(--awTabHeadingGap, 0);"
    >
      <client-only>
        <slot name="title" />
      </client-only>
      <template v-if="!hideHeaderItems">
        <client-only>
          <aw-tab-header-items
            :items="headerItems"
            :tab-type="tabType"
            :class="$style.tabHeaderItems"
            data-tab-items
            @select-tab-item="selectTabItem"
          />
        </client-only>
      </template>
    </div>
    <div :class="$style.tabContent">
      <client-only>
        <slot name="content" />
      </client-only>
    </div>
  </div>
</template>
<script setup>
  import { provide, ref, watch, onBeforeMount, onUnmounted, onMounted, computed } from 'vue';
  import { uuid4 } from '~~/common/utils';
  import { useTabStore } from '~~/common/stores/tab';
  import AwTabHeaderItems from '~~/common/components/Common/AwTabHeaderItems.vue';
  import { useNuxtApp } from 'nuxt/app';

  const props = defineProps({
    hideHeaderItems: {
      type: Boolean,
      default: false,
    },
    tabType: {
      type: String,
      required: true,
      validator: type => ['page', 'container'].includes(type),
    },
    headerFlexType: {
      type: String,
      default: 'row',
      validator: type => ['row', 'column'].includes(type),
    },
    headerItems: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits(['select-tab-item']);

  const tabStore = useTabStore();
  const {
    $router,
  } = useNuxtApp();

  const uuid = ref(null);
  provide('uuid', uuid);

  const selectedTabIndex = computed(() => {
    const index = props.headerItems.findIndex(item => {
      if (item.to) {
        return $router.currentRoute.value.fullPath === item.to || item.alternativeRoutePaths?.includes($router.currentRoute.value.fullPath);
      } else {
        return tabStore.tabs[uuid.value] === item.tabItemId;
      }
    });
    return index === -1 ? 0 : index;
  });

  watch(() => props.headerItems, (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      setTab({
        uuid: uuid.value,
        index: props.headerItems[selectedTabIndex.value]?.tabItemId,
      });
    }
  });
  onBeforeMount(() => {
    uuid.value = uuid4();
  });
  onMounted(() => {
    if (props.headerItems.length > 0) {
      setTab({
        uuid: uuid.value,
        index: props.headerItems[selectedTabIndex.value]?.tabItemId,
      });
      emit('select-tab-item', 0);
    }
  });
  onUnmounted(() => {
    removeTab({ uuid: uuid.value });
  });

  function setTab () {
    return tabStore.setTab(...arguments);
  }

  function removeTab () {
    return tabStore.removeTab(...arguments);
  }

  function selectTabItem ({
    uuid,
    index,
  }) {
    setTab({
      uuid: uuid,
      index,
    });
    emit('select-tab-item', index);
  }


</script>

<style module lang="scss" rel="stylesheet/scss">
.tab {
  display: flex;
  flex-direction: column;

  &Header {
    position: relative;
    display: flex;
    justify-content: space-between;


    &-page {
      /*page level tab*/
      justify-content: center;
    }

    &-container {
      /*container level tab*/

      @include tablet(min) {
        max-width: calc(100% - 100px);
      }
    }

    &Items {
      display: flex;
      @include mobile(max) {
        flex-grow: 1;
      }
    }

    &Flex {
      &-column {
        flex-direction: column;
      }

      &-row {
        flex-direction: row;
      }
    }
  }

  &Content {
    flex-grow: 1;
  }
}
</style>
