<template>
  <ul :class="[$style.sidebar, 'ulReset']">
    <li v-for="section in sections" :key="section.id" :class="[$style.sidebarItem, {[$style.sidebarItemActive]: isActiveSection(section.id)}]">
      <a :href="`#${section.id}`" :aria-current="isActiveSection(section.id).toString()">
        {{ section.name }}
      </a>
      <lv-icon name="arrow-right-24" :size="16" :class="$style.sidebarItemIcon" />
    </li>
  </ul>
</template>

<script>

  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwScrollSectionMixin from './AwScrollSectionMixin.js';
  export default {
    name: 'AwScrollSectionSidebar',
    components: {
      LvIcon,
    },
    mixins: [
      AwScrollSectionMixin,
    ],
    props: {
      sections: {
        type: Array,
        required: true,
      },
    },
  };

</script>


<style module lang="scss" rel="stylesheet/scss">
.sidebar {
  display: grid;
  height: min-content;
  padding: 8px;
  border-radius: 12px;
  background-color: $color-background-3;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @include tablet (min) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include desktop-medium (min) {
    grid-template-columns: repeat(1, 1fr);
  }

  &Item {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    word-break: break-word;
    hyphens: auto;
    border-radius: 8px;
    background-color: $color-background-4;
    @include desktop-medium (min) {
      font-size: 14px;
    }

    &Active {
      color: $color-white;
      background-color: $color-strawberry-450;

      .sidebar {
        &Item {
          &Icon {
            display: none;
          }
        }
      }
    }
  }
}
</style>
