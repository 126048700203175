<template>
  <div
    ref="componentRef"
    :class="[$style[styling], {[$style.inline] : inline}]"
    :style="{
      '--reference-width': `${referenceWidth}px`
    }"
  >
    <component
      :is="isFalseTooltip ? 'div' : 'button'"
      v-if="isButtonAutomatic"
      ref="referenceRef"
      tabindex="0"
      :class="['buttonReset', $style.referenceButton]"
      :type="isFalseTooltip ? null : 'button'"
      v-bind="buttonAttrs"
    >
      <slot name="reference" />
    </component>
    <template v-else>
      <div
        ref="referenceRef"
      >
        <slot name="reference" />
      </div>
    </template>
    <div
      v-show="isFalseTooltip || !isHidden"
      :id="uuid"
      ref="popperRef"
      data-popper-element
      :role="!isFalseTooltip && trigger === 'hover' ? 'tooltip' : null"
      :class="[
        $style.popper,
        {
          awSrOnlyAbs: isFalseTooltip && isHidden,
        },
      ]"
    >
      <slot name="tooltip">
        {{ content }}
      </slot>
    </div>
  </div>
</template>

<script setup>
  import Popper from 'popper.js';
  import { useId } from 'nuxt/app';
  import { ref, computed, watch, nextTick, useCssModule, onMounted, onUnmounted } from 'vue';

  function on (element, event, handler) {
    if (element && event && handler) {
      element.addEventListener(event, handler, false);
    }
  }

  function off (element, event, handler) {
    if (element && event) {
      element.removeEventListener(event, handler, false);
    }
  }

  const DELAY_ON_MOUSE_OUT = 10;
  const DELAY_ON_MOUSE_OVER = 10;
  const props = defineProps({
    showTooltip: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: String,
      default: 'hover',
      validator: value => [
        'none',
        'click',
        'hover',
      ].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: null,
    },
    isButtonAutomatic: {
      type: Boolean,
      default: true,
    },
    isFalseTooltip: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default () {
        return {};
      },
    },
    styling: {
      type: String,
      default: 'primary',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    ariaLabelledby: {
      type: String,
      default: null,
    },
    referenceWithOverwrite: {
      type: Number,
      default: null,
    },
    buttonAttrs: {
      type: Object,
      default: () => ({}),
    },
  });
  const emits = defineEmits([
    'update:showTooltip',
  ]);

  const $style = useCssModule();

  let appendedArrow = false;
  let mouseTimer = null;
  const popperOptions = {
    placement: 'bottom',
    computeStyle: {
      gpuAcceleration: false,
    },
    ...props.options,
  };
  let popperJS = null;

  const componentRef = ref();
  const referenceRef = ref();
  const popperRef = ref();
  const uuid = useId();
  const referenceElm = ref(null);
  const showPopper = ref(false);
  const referenceWidth = ref(0);
  const isHidden = computed(() => props.disabled || !showPopper.value);

  watch(isHidden, (newVal, oldVal) => {
    if (newVal !== oldVal && newVal) {
      doDestroy();
    }
  });

  watch(
    () => props.showTooltip,
    (newVal) => {
      if (showPopper.value !== newVal) {
        showPopper.value = Boolean(newVal);
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    showPopper,
    (value) => {
      if (value) {
        emits('update:showTooltip', true);
        if (popperJS) {
          popperJS.enableEventListeners();
        }
        updatePopper();
      } else {
        if (popperJS) {
          popperJS.disableEventListeners();
        }
        emits('update:showTooltip', false);
      }
      if (!props.isFalseTooltip) {
        setReferenceAriaExpanded(value);
      }
    },
  );

  watch(
    () => props.disabled,
    (value) => {
      if (value) {
        showPopper.value = false;
      }
    },
  );

  onMounted(() => {
    referenceElm.value = (
      (props.isButtonAutomatic
        ? null
        : componentRef.value.querySelector('[data-lv-tootip-reference]')
      ) ||
      referenceRef.value
    );

    switch (props.trigger) {
    case 'click':
      on(document, 'click', handleDocumentClick);
      on(document, 'keydown', onKeyDown);
      break;
    case 'hover':
      referenceElm.value.setAttribute('aria-describedby', uuid);

      if (props.ariaLabelledby) {
        referenceElm.value.setAttribute('aria-labelledby', props.ariaLabelledby);
      }
      on(referenceElm.value, 'mouseover', onMouseOver);
      on(popperRef.value, 'mouseover', onMouseOver);
      on(referenceElm.value, 'mouseout', onMouseOut);
      on(popperRef.value, 'mouseout', onMouseOut);
      on(referenceElm.value, 'focus', onMouseOver);
      on(popperRef.value, 'focus', onMouseOver);
      on(referenceElm.value, 'blur', onMouseOut);
      on(popperRef.value, 'blur', onMouseOut);
      on(document, 'keydown', onKeyDown);
      break;
    }

    referenceWidth.value = props.referenceWithOverwrite || referenceElm?.value?.offsetWidth || 0;
  });

  onUnmounted(() => {
    destroyPopper();
  });

  function doShow () {
    showPopper.value = true;
  }

  function doClose () {
    showPopper.value = false;
  }

  function doDestroy () {
    if (showPopper.value) {
      return;
    }

    if (popperJS) {
      popperJS.destroy();
      popperJS = null;
    }
  }

  function createPopper () {
    nextTick(() => {
      appendArrow();

      if (popperJS && popperJS.destroy) {
        popperJS.destroy();
      }

      popperOptions.onCreate = () => {
        nextTick(updatePopper);
      };

      popperOptions.modifiers = Object.assign({}, popperOptions.modifiers);
      popperOptions.modifiers.arrow = Object.assign({}, popperOptions.modifiers.arrow, { element: '[data-x-arrow]' });

      popperJS = new Popper(referenceElm.value, popperRef.value, popperOptions);
    });
  }

  function destroyPopper () {
    off(referenceElm.value, 'mouseup', doClose);
    off(referenceElm.value, 'mousedown', doShow);
    off(referenceElm.value, 'focus', doShow);
    off(referenceElm.value, 'blur', doClose);
    off(referenceElm.value, 'mouseout', onMouseOut);
    off(referenceElm.value, 'mouseover', onMouseOver);
    off(document, 'click', handleDocumentClick);
    off(document, 'keydown', onKeyDown);

    showPopper.value = false;
    doDestroy();
  }

  function appendArrow () {
    if (appendedArrow) {
      return;
    }

    appendedArrow = true;

    const arrow = document.createElement('div');
    arrow.setAttribute('data-x-arrow', '');
    arrow.className = $style.popperArrow;
    popperRef.value.appendChild(arrow);
  }

  function updatePopper () {
    popperJS ? popperJS.scheduleUpdate() : createPopper();
  }

  function onMouseOver () {
    clearTimeout(mouseTimer);
    mouseTimer = setTimeout(() => {
      showPopper.value = true;
    }, props?.options?.delayOnMouseOver || DELAY_ON_MOUSE_OVER);
  }

  function onMouseOut () {
    clearTimeout(mouseTimer);
    mouseTimer = setTimeout(() => {
      showPopper.value = false;
    }, props?.options?.delayOnMouseOut || DELAY_ON_MOUSE_OUT);
  }

  function onKeyDown (e) {
    if (e.keyCode === 27) {
      doClose();
    }
  }

  function handleDocumentClick (e) {
    if (!componentRef.value || !referenceElm.value ||
      elementContains(componentRef.value, e.target) ||
      elementContains(referenceElm.value, e.target) ||
      !popperRef.value || elementContains(popperRef.value, e.target)
    ) {
      return;
    }

    showPopper.value = false;
  }

  function elementContains (elm, otherElm) {
    if (typeof elm.contains === 'function') {
      return elm.contains(otherElm);
    }

    return false;
  }

  function setReferenceAriaExpanded (value) {
    referenceElm.value.setAttribute('aria-expanded', value.toString());
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
$lv-tooltip-font-size: 13px;
$lv-tooltip-border-radius: 8px;
$lv-tooltip-arrow-height: 12px;
$lv-tooltip-arrow-width: 16px;
$lv-tooltip-padding: 16px;
$lv-tooltip-secondary-version2-max-height: 208px;
$lv-tooltip-styles: (
    primary: (
        background: $color-white,
        color: $color-black,
        border: 1px $color-white-transparent solid,
        boxShadow: $color-mercury 0 12px 24px 0
    ),
    secondary: (
        background: $color-seashell,
        color: $color-black,
        border: 1px $color-white-transparent solid,
        boxShadow: none
    ),
    primary-version2: (
        background: $color-plum,
        color: $color-text-inverse,
        border: none,
        boxShadow: none,
        maxWidth: 360px,
        borderRadius: 12px,
        padding: 16px 24px,
        fontSizeMobile:12px,
        fontSizeDesktop:14px,
        lineHeightMobile:16px,
        lineHeightDesktop:20px,
        fontWeightMobile: $font-weight-regular-v2,
        fontWeightDesktop:$font-weight-normal-v2
    ),
    secondary-version2: (
        background: $color-background-4,
        color: $color-black,
        border: none,
        boxShadow: none,
        filter: drop-shadow(0 8px 24px $color-olive-750--12),
        maxWidth: 256px,
        maxHeight: $lv-tooltip-secondary-version2-max-height,
        borderRadius: 12px,
        padding: 8px,
        fontSizeMobile:12px,
        fontSizeDesktop:14px,
        lineHeightMobile:16px,
        lineHeightDesktop:20px,
        fontWeightMobile: $font-weight-regular-v2,
        fontWeightDesktop:$font-weight-normal-v2
    ),
);

@function lv-tooltip-opposite($side) {
  @if $side == top {
    @return bottom;
  } @else if $side == top-start {
    @return bottom;
  } @else if $side == top-end {
    @return bottom;
  } @else if $side == right {
    @return left;
  } @else if $side == bottom {
    @return top;
  } @else if $side == bottom-start {
    @return top;
  } @else if $side == bottom-end {
    @return top;
  } @else if $side == left {
    @return right;
  } @else {
    @error "Unknown side";
  }
}

@function lv-tooltip-same-axis-top-or-left($side) {
  @if $side == top {
    @return top;
  } @else if $side == top-start {
    @return top;
  } @else if $side == top-end {
    @return top;
  } @else if $side == right {
    @return left;
  } @else if $side == bottom {
    @return top;
  } @else if $side == bottom-start {
    @return top;
  } @else if $side == bottom-end {
    @return top;
  } @else if $side == left {
    @return left;
  } @else {
    @error "Unknown side";
  }
}

@function lv-tooltip-opposite-axis-top-or-left($side) {
  @if $side == top {
    @return left;
  } @else if $side == top-start {
    @return left;
  } @else if $side == top-end {
    @return left;
  } @else if $side == right {
    @return top;
  } @else if $side == bottom {
    @return left;
  } @else if $side == bottom-start {
    @return left;
  } @else if $side == bottom-end {
    @return left;
  } @else if $side == left {
    @return top;
  } @else {
    @error "Unknown side";
  }
}

@mixin lv-tooltip-generate-popper($side, $color) {
  .popper[x-placement^="#{$side}"] .popperArrow {
    border-#{lv-tooltip-opposite($side)}-width: 0;
    background: $color;
    #{lv-tooltip-opposite($side)}: calc(-#{$lv-tooltip-arrow-height} + 4px);
    #{lv-tooltip-opposite-axis-top-or-left($side)}: calc(50% - #{$lv-tooltip-arrow-width});
    margin-#{lv-tooltip-same-axis-top-or-left($side)}: 0;
    margin-#{lv-tooltip-opposite(lv-tooltip-same-axis-top-or-left($side))}: 0;
  }
}

.popper {
  font-size: $lv-tooltip-font-size;
  font-weight: normal;
  position: absolute;
  z-index: 200000;
  top: -2px !important;
  display: inline-block;
  width: auto;
  padding: $lv-tooltip-padding;
  text-align: center;
  border-radius: $lv-tooltip-border-radius;
}

.popperArrow {
  position: absolute;
  z-index: -1;
  width: 17px; /* 24px / sqrt(2) */
  height: 17px; /* 24px / sqrt(2) */
  border: none;
}

.popper[x-placement] .popperArrow {
  border-width: $lv-tooltip-arrow-height;
  border-color: transparent;
}

.popper[x-placement^="top"] {
  margin-bottom: $lv-tooltip-arrow-height;

  .popperArrow {
    transform: scaleX(.666) rotate(45deg);
    border-radius: 0 0 2px;
  }
}

.popper[x-placement^="bottom"] {
  margin-top: $lv-tooltip-arrow-height;

  .popperArrow {
    transform: scaleX(.666) rotate(45deg);
    border-radius: 4px 0 0;
  }
}

.popper[x-placement^="right"] {
  margin-left: $lv-tooltip-arrow-height;

  .popperArrow {
    transform: scaleY(.666) rotate(45deg);
    border-radius: 0 0 0 2px;
  }
}

.popper[x-placement^="left"] {
  margin-right: $lv-tooltip-arrow-height;

  .popperArrow {
    transform: scaleY(.666) rotate(45deg);
    border-radius: 0 2px 0 0;
  }
}

.inline {
  display: inline-block;
}

@each $name, $s in $lv-tooltip-styles {
  .#{$name} {
    .popper {
      max-width: map-get($s, maxWidth);
      max-height: map-get($s, maxHeight);
      padding: map-get($s, padding);
      color: map-get($s, "color");
      border: map-get($s, "border");
      border-radius: map-get($s, borderRadius);
      background-color: map-get($s, "background");
      box-shadow: map-get($s, "boxShadow");
      filter: map-get($s, filter);

      @include mobile(max) {
        font-size: map-get($s, fontSizeMobile);
      }

      @include tablet(min) {
        font-size: map-get($s, fontSizeDesktop);
      }

      @include mobile(max) {
        line-height: map-get($s, lineHeightMobile);
      }

      @include tablet(min) {
        line-height: map-get($s, lineHeightDesktop);
      }

      @include mobile(max) {
        font-weight: map-get($s, fontWeightMobile);
      }

      @include tablet(min) {
        font-weight: map-get($s, fontWeightDesktop);
      }
    }

    @each $side in (top-start, top, top-end, right, bottom-start, bottom, bottom-end, left,) {
      @include lv-tooltip-generate-popper($side, map-get($s, "background"));
    }
  }
}

.primary-version2 {
  .popper {
    width: 320px;
  }

  [data-popover-item-wrapper] {
    text-align: left !important;
  }

  .popperArrow {
    border-bottom-right-radius: 5px !important;
  }
}

.secondary-version2 {
  .popper[x-placement^="bottom-end"] .popperArrow,
  .popper[x-placement^="top-end"] .popperArrow {
    // Explanation:  100% - popper overflow right - popper arrow width / 2 - reference with / 2
    #{lv-tooltip-opposite-axis-top-or-left('bottom-end')}: calc(100% - 46px - 8px - (var(--reference-width, 0px) / 2)) !important;
  }

  .popper[x-placement^="bottom-end"],
  .popper[x-placement^="top-end"] {
    left: 46px !important;
  }

  .popper {
    [data-popover-item-wrapper] {
      overflow: auto;
      width: 240px;
      max-height: calc(#{$lv-tooltip-secondary-version2-max-height} - 8px);
      text-align: left;

      [data-popover-item] {
        padding: 8px;
        text-decoration: none;
        border-radius: 8px;

        &:hover {
          background: $color-background-3;
        }

        &:active {
          background: $color-background-2;
        }

        &[data-popover-item-selected=true] {
          color: $color-marigold-text;
          background: $color-background-pending;
        }
      }
    }
  }
}

.referenceButton {
  display: block;
}
</style>
