<template>
  <div :class="$style.wrapper">
    <div :class="$style.wrapperSide">
      <div :class="$style.wrapperSideContainer">
        <div v-if="hasSlotContent('overSidebar')" :class="$style.wrapperSideContainerOver">
          <slot name="overSidebar" />
        </div>
        <div
          v-if="!!selectedOption"
          :class="$style.wrapperSideContainerMenu"
        >
          <div
            :class="[$style.wrapperSideContainerMenuSidebar, {[$style.overLimit]: menuItemsOverLimit}]"
          >
            <aw-sidebar
              v-model:selected-options="selectedOption"
              :options="menuItems"
              :notifications="notification"
              @navigate="afterNavigation"
            />
          </div>
          <div :class="[$style.wrapperSideContainerMenuSelect, {[$style.overLimit]: menuItemsOverLimit}]">
            <aw-select-version2
              v-model:selected-options="selectedOption"
              :options="menuItems"
              option-id-property="id"
              option-label-property="name"
              :multiple="false"
              :dropdown-icon-attrs="{
                name: 'arrow-down-16',
                size: 20
              }"
              :class="[$style.wrapperSideContainerMenuSelect, {[$style.overLimit]: menuItemsOverLimit}]"
              @update:selected-options="selectMenuItem"
            />
          </div>
        </div>
        <div v-if="hasSlotContent('underSidebar')" :class="$style.wrapperSideContainerUnder">
          <slot name="underSidebar" />
        </div>
      </div>
    </div>
    <div :class="$style.wrapperContent">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
  // Component imports
  import { defineAsyncComponent, ref, onMounted, watch, useSlots } from 'vue';
  import { navigateTo } from 'nuxt/app';

  const AwSelectVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwSelectVersion2'));
  const AwSidebar = defineAsyncComponent(() => import('./AwSidebar.vue'));

  // Config
  const MENU_ITEMS_SIDEBAR_LIMIT = 9;

  // IO definitions
  const props = defineProps({
    menuItems: {
      type: Array,
      required: true,
    },
    notification: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: [Object],
      default: null,
    },
  });

  const emits = defineEmits(['navigate']);

  const slots = useSlots();

  // Data
  const selectedOption = ref(null);

  // Computed
  const menuItemsOverLimit = computed(() => props.menuItems.length >= MENU_ITEMS_SIDEBAR_LIMIT);

  // Methods
  function selectMenuItem (selectedOption) {
    if (selectedOption.link) {
      navigateTo(selectedOption.link);
      return;
    }
    if (selectedOption.anchor) {
      document.querySelector(`#${selectedOption.anchor}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return;
    }
    emits('navigate', selectedOption);
  }

  function afterNavigation (payload) {
    emits('navigate', payload);
  }

  function hasSlotContent (name) {
    return !!slots[name];
  }

  // Lifecycle hooks
  onMounted(() => {
    selectedOption.value = props.selectedItem;
  });

  watch(() => props.selectedItem, (newValue) => {
    selectedOption.value = newValue;
  });


</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  display: grid;
  gap: 20px;

  @include tablet (min) {
    gap: 40px;
  }

  @include sidebar-desktop-medium-large (min) {
    grid-template-columns: repeat(10, 1fr);
  }

  &Side {
    @include sidebar-desktop-medium-large (min) {
      grid-column: span 2;
      position: relative;
    }

    &Container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include sidebar-desktop-medium-large (min) {
        position: sticky;
        top: calc(36px + var(--mainOffset) + var(--notification-bar-height, 0) + var(--editedOrderBarHeight));
      }

      &Menu {
        &Select {
          display: none;

          &.overLimit {
            display: block;
            height: 36px;
            @include sidebar-desktop-medium-large (min) {
              display: none;
            }
          }
        }

        &Sidebar {
          display: block;

          &.overLimit {
            display: none;
            @include sidebar-desktop-medium-large (min) {
              display: block;
            }
          }
        }
      }
    }
  }

  &Content {
    @include sidebar-desktop-medium-large (min) {
      grid-column: span 8;
    }
  }
}
</style>
