<script>
  import { defineAsyncComponent, resolveComponent, h, ref, computed, watch } from 'vue';
  import { LvHeading, LvButton, LvIcon, LvTooltip } from '~~/common/components/loginet-vue-shop/index.mjs';
  import abstract77 from '~~/common/assets/images/abstract-77.png';
  import abstract77Webp from '~~/common/assets/images/abstract-77.png.webp';
  import { arrayChunk } from '~~/common/utils/arrayChunk';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  const AwTabVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwTabVersion2'));
  const AwTabContentVersion2 = defineAsyncComponent(() => import('~~/common/components/Common/AwTabContentVersion2'));

  const isValidKey = key => key || key === 0 || key === false;

  const SLIDE_LAYOUT_AUTO = 'auto';

  export default {
    name: 'AwTabCarousel',
    props: {
      tabs: {
        type: Array,
        required: true,
      },
      slides: {
        type: Object,
        required: true,
      },
      breakpoints: {
        type: Array,
        required: false,
        default: null,
      },
      tabKey: {
        type: String,
        required: true,
      },
      slideKey: {
        type: String,
        required: true,
      },
      mainTitle: {
        type: String,
        default: null,
      },
      tooltip: {
        type: String,
        default: '',
      },
      emptyPlaceholder: {
        type: String,
        default: null,
      },
      headingTag: {
        type: String,
        default: 'h2',
      },
      buttonTexts: {
        type: Object,
        default: null,
      },
      isCarousel: {
        type: Boolean,
        default: true,
      },
      hasTitlePadding: {
        type: Boolean,
        default: true,
      },
      flexDirection: {
        type: String,
        default: 'row',
        validator: (value) => ['row', 'column'].includes(value),
      },
    },
    emits: [
      'tabOpened',
      'renderSubSlides',
    ],
    setup (props, context) {
      const {
        count: breakpointCount,
        columnGap,
      } = ((Array.isArray(props.breakpoints) ? props.breakpoints?.find(bp => bp?.at) : undefined) || { count: SLIDE_LAYOUT_AUTO });

      const carouselRef = ref();
      const currentTabItem = ref(null);

      const userInterfaceStore = useUserInterfaceStore();

      const tabsArr = computed(() => {
        return Array.isArray(props.tabs) ? props.tabs : [];
      });

      const isTab = computed(() => {
        return Boolean(tabsArr.value.length !== 1 || tabsArr.value[0]?.tabTitle);
      });

      const isCarousel = computed(() => {
        return props.isCarousel && userInterfaceStore.mediaQueries['tablet-min'];
      });

      const hasTitlePadding = computed(() => {
        return props.hasTitlePadding;
      });

      const hasHeaderItems = computed(() => {
        return tabsArr.value.length > 1;
      });

      const headerFlexType = computed(() => {
        return props.flexDirection;
      });

      const rawSlidesByTab = computed(() => tabsArr.value.map((tabItem) => {
        const tk = tabItem?.[props.tabKey];
        const rawSlides = (
          (Array.isArray(props.slides[tk]) ? props.slides[tk] : []).filter((rawSlide) => {
            const type = typeof rawSlide;
            if (type !== 'object') {
              // $logger.warn(`slide at index ${idx} for tabKey "${tk}" expected to be "object", "${type}" given`);
              return false;
            }
            return true;
          })
        );

        if (props.slides[tk] === undefined) {
          // $logger.warn(`slides are undefined for tabKey: ${tk}`);
        } else if (!Array.isArray(props.slides[tk])) {
          // $logger.warn(`slides given must be an array for tabKey: ${tk}`);
        }

        return rawSlides;
      }));

      const cardsPerSlidesByTab = computed(() => tabsArr.value.map((_, i) => {
        return breakpointCount === SLIDE_LAYOUT_AUTO ? rawSlidesByTab.value[i].length : breakpointCount;
      }));

      const tabSlidesByTab = computed(() => tabsArr.value.map((_, i) => {
        const slideGroups = arrayChunk({
          inputArr: rawSlidesByTab.value[i],
          chunkLength: cardsPerSlidesByTab.value[i],
          trimEnd: false,
          placeholder: undefined,
        });

        return slideGroups.filter(arr => Array.isArray(arr)).map((arr) => {
          const sk = arr.length ? arr[0][props.slideKey] : undefined;
          const isKeyValid = isValidKey(sk);
          if (!isKeyValid) {
            // this.$logger.warn(`slide group has no valid slideKey property ${props.slideKey}: ${sk}`);
          }
          return {
            key: isKeyValid ? sk : undefined,
            subslides: arr.filter(it => it !== undefined),
          };
        });
      }));

      watch(isTab, (newVal, oldVal) => {
        if (newVal !== oldVal && !newVal) {
          const newTabItem = tabsArr.value[0];
          if (newTabItem) {
            context.emit('tabOpened', {
              oldTabItem: currentTabItem.value,
              newTabItem,
            });
            currentTabItem.value = newTabItem;
          }
        }
      }, { immediate: true });

      return function () {
        const titleSlot = () => {
          const children = [
            (this.mainTitle
              ? h(
                LvHeading,
                {
                  class: [this.$style.mainTitle, { [this.$style.mainTitleMargined]: isTab.value }],
                  tag: this.headingTag,
                  level: '2',
                  version2: true,
                },
                {
                  default: () => this.mainTitle,
                },
              )
              : null
            ),
            (this.tooltip.length
              ? h(
                LvTooltip,
                {
                  options: {
                    placement: 'top',
                  },
                  trigger: 'hover',
                  inline: true,
                  styling: 'primary-version2',
                  isFalseTooltip: true,
                },
                {
                  reference: () => h(LvIcon, {
                    name: 'education-16',
                    size: 16,
                    class: this.$style.tooltipIcon,
                  }),
                  tooltip: () => h(
                    'div',
                    {},
                    {
                      default: () => this.tooltip,
                    },
                  ),
                },
              )
              : null),
          ];
          if (isTab.value) {
            return children;
          } else {
            return h(resolveComponent('client-only'), {}, {
              default: () => [
                h(
                  'div',
                  {
                    style: hasTitlePadding.value && 'padding-left: var(--awTabHeadingLeft, 0);padding-right: var(--awTabHeadingRight, 0)',
                    class: this.$style.titleWrapper,
                  },
                  children,
                )],
            });

          }
        };
        const contentsSlot = () => {
          const children = tabsArr.value.map((tabItem, tabItemIndex) => {
            const tk = tabItem?.[this.tabKey];
            const isKeyValid = isValidKey(tk);

            if (!isKeyValid) {
              this.$logger.warn(`tab has no valid tabKey property "${this.tabKey}": ${tk}`);
            }

            const renderSlides = (slideGroup) => h(
              resolveComponent('LazyHydrate'),
              { attr: { 'when-visible': true } },
              {
                default: () => {
                  return h(
                    'div',
                    {
                      class: [this.$style.slideGroupHolder],
                      style: {
                        'grid-template-columns': (breakpointCount !== SLIDE_LAYOUT_AUTO
                          ? `repeat(${cardsPerSlidesByTab.value[tabItemIndex]}, 1fr)`
                          : null
                        ),
                        'column-gap': columnGap || null,
                      },
                    },
                    renderSubSlides.call(this, tk, tabItem, slideGroup),
                  );
                },
              },
            );

            return h(
              isTab.value ? AwTabContentVersion2 : 'div',
              {
                key: isValidKey ? `tab_content_${tk}` : null,
                tabIndex: tabItemIndex,
                keepDom: false,
              },
              {
                default: () => [
                  isCarousel.value && tabSlidesByTab.value[tabItemIndex].length > 1 ? renderLeftAndRightArrow.call(this, isTab) : null,
                  h(
                    'div',
                    { class: [this.$style.carouselParent, { [this.$style.carouselParentNoHeader]: !hasHeaderItems.value && !this.mainTitle }] },
                    h(
                      resolveComponent('client-only'),
                      {},
                      {
                        default: () => {
                          if (isCarousel.value) {
                            return h(
                              resolveComponent('b-carousel'),
                              {
                                ref: carouselRef,
                                interval: 0,
                              },
                              {
                                default: () => {
                                  if (tabSlidesByTab.value[tabItemIndex].length) {
                                    return tabSlidesByTab.value[tabItemIndex].map((slideGroup) => {
                                      return h(
                                        resolveComponent('b-carousel-slide'),
                                        {
                                          key: slideGroup.key !== undefined ? `carousel_slide_${slideGroup.key}` : null,
                                          background: 'transparent',
                                        },
                                        {
                                          img: () => {
                                            return renderSlides(slideGroup);
                                          },
                                        },
                                      );
                                    });
                                  } else {
                                    return renderFallbackSlide.call(this, tk, tabItem, isCarousel.value);
                                  }
                                },
                              },
                            );
                          } else {
                            if (tabSlidesByTab.value[tabItemIndex].length) {
                              return tabSlidesByTab.value[tabItemIndex].map((slideGroup) => {
                                return renderSlides(slideGroup);
                              });
                            } else {
                              return renderFallbackSlide.call(this, tk, tabItem, isCarousel.value);
                            }
                          }
                        },
                      },
                    ),
                  ),
                  isCarousel.value ? h(
                    LvButton,
                    {
                      class: ['awSrOnly', this.$style.awSrOnly],
                      styling: 'small-secondary-dark-just-icon',
                      shrink: true,
                      title: this.buttonTexts?.next || this.$awt('aw.tabcarousel.next'),
                      onClick: () => scrollDir('next'),
                    },
                  ) : null,
                ],
              },
            );
          });
          if (isTab.value) {
            return children;
          } else {
            return h('div', null, children);
          }
        };

        return h(
          'div',
          { class: this.$style.relativeParent },
          [
            h(
              isTab.value ? AwTabVersion2 : 'div',
              {
                tabType: 'container',
                headerFlexType: headerFlexType.value,
                hideHeaderItems: !hasHeaderItems.value,
                headerItems: tabsArr.value.map((tabItem, i) => ({
                  label: tabItem?.tabTitle,
                  tabItemId: i,
                })),
                onSelectTabItem: (x) => {
                  const selectedTabItem = {
                    ...tabsArr.value[x],
                    tabItemId: x,
                  };
                  this.$emit('tabOpened', {
                    oldTabItem: currentTabItem.value,
                    newTabItem: selectedTabItem,
                  });
                  currentTabItem.value = selectedTabItem;
                },
              },
              (isTab.value
                ? {
                  title: titleSlot,
                  content: contentsSlot,
                }
                : [
                  titleSlot(),
                  contentsSlot(),
                ]
              ),
            ),
          ],
        );
      };

      function scrollDir (direction) {
        carouselRef.value?.[direction]?.();
      }

      function renderLeftAndRightArrow (isTab) {
        return h(
          'div',
          {
            class: [this.$style.navigation, { [this.$style.navigationPadding]: (isTab.value && !hasHeaderItems.value) || hasTitlePadding.value }, { [this.$style.hasMainTitle]: this.mainTitle || hasHeaderItems.value }, { [this.$style.hasHeaderItems]: hasHeaderItems.value }],
          },
          [
            {
              id: 'prev',
              token: this.buttonTexts?.prev || this.$awt('aw.tabcarousel.prev'),
              icon: 'arrow-left-16',
              ariaHidden: false,
              tabindex: 0,
            },
            {
              id: 'next',
              token: this.buttonTexts?.next || this.$awt('aw.tabcarousel.next'),
              icon: 'arrow-right-16',
              ariaHidden: true,
              tabindex: -1,
            },
          ].map(d => h(
            LvButton,
            {
              key: d.id,
              styling: 'small-secondary-dark-just-icon',
              shrink: true,
              title: d.token,
              'aria-hidden': d.ariaHidden?.toString(),
              tabindex: d.tabindex,
              onClick: () => scrollDir(d.id),
            },
            {
              default: () => h(LvIcon, {
                class: [this.$style.arrowIcon],
                name: d.icon,
                size: 16,
              }),
            },
          )),
        );
      }

    },
  };

  function renderSubSlides (tabKey, tabItem, slideGroup) {
    this.$emit('renderSubSlides');
    return slideGroup.subslides.map((slide) => {
      const sslots = this.$slots;
      const itemKey = slide[this.slideKey];
      const sk = isValidKey(itemKey) ? itemKey : slideGroup.key;
      const selectedSlot = (
        sslots[`slide(${tabKey})(${sk})`] ||
        sslots[`slide(all)(${sk})`] ||
        sslots[`slide(${tabKey})(all)`] ||
        sslots['slide(all)(all)']
      );
      if (typeof selectedSlot === 'function') {
        return selectedSlot({
          tabItem,
          slide,
        });
      }

      return h('div', {}, `Error: AwTabCarousel has no slide template for #slide(${tabKey})(${sk}) or any fallback to #slide(all)(all)!`);
    });
  }

  const fallbackSlideComponent = {
    name: 'fallbackSlideComponent',
    props: {
      emptyPlaceholder: {
        type: String,
        required: true,
      },
    },
    setup (props) {
      return function () {
        return h(
          'div',
          [
            h(
              'div',
              {
                innerHTML: props.emptyPlaceholder || this.$awt('aw.common.card_carouel.default.no_items_to_show'),
              },
            ),
            h(
              'img',
              {
                src: this.$addWebpPostfixIfSupported({
                  url: abstract77,
                  webpUrl: abstract77Webp,
                }),
                alt: '',
                width: '144',
                height: '144',
              },
            ),
          ],
        );
      };
    },
  };

  function renderFallbackSlide (tabKey, tabItem, isCarousel) {

    const renderContent = () => {
      const selectedSlot = (
        this.$slots[`slide(${tabKey})(fallbackTemplate)`] ||
        this.$slots['slide(all)(fallbackTemplate)']
      );

      if (typeof selectedSlot === 'function') {
        return selectedSlot({
          tabItem,
          slide: null,
        });
      } else {
        return h(
          fallbackSlideComponent,
          {
            emptyPlaceholder: this.emptyPlaceholder,
            class: this.$style.fallbackSlide,
          },
        );
      }
    };

    return isCarousel ? h(
      resolveComponent('b-carousel-slide'),
      {
        key: null,
        background: 'transparent',
      },
      {
        img: () => {
          return renderContent();
        },
      },
    ) : renderContent();
  }
</script>
<style module lang="scss" rel="stylesheet/scss">
.relativeParent {
  --awTabHeadingRight: 16px;
  --awTabHeadingLeft: 16px;
  position: relative;
}

.navigation {
  position: absolute;
  right: var(--awTabHeadingRight, 0);
  display: flex;
  gap: 8px;
  transform: translateY(-100%);

  &Padding {
    margin-top: -32px;
    padding: 8px 0;

    &.hasMainTitle {
      margin-top: 8px;
    }

    &.hasHeaderItems {
      margin-top: 2px;
    }
  }
}

.carouselParent {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--awTabCarouselCarouselParentGap, 16px);

  @include tablet(min) {
    row-gap: var(--awTabCarouselCarouselParentGap, 24px);
  }

  @include desktop-small(min) {
    row-gap: var(--awTabCarouselCarouselParentGap, 32px);
  }

  @include desktop-medium(min) {
    row-gap: var(--awTabCarouselCarouselParentGap, 40px);
  }

  min-height: var(--awTabCarouselParentMinHeight, 368px);
  padding-top: var(--awTabCarouselParentPaddingTop, 20px);

  @include tablet(min) {
    min-height: var(--awTabCarouselMinHeight, 336px);
    padding-top: var(--awTabCarouselParentPaddingTop, 40px);
  }

  &NoHeader {
    padding-top: 0;
  }
}

.slideGroupHolder {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  justify-content: space-between;
  column-gap: var(--awTabCarouselSlideGroupGap, 16px);

  @include mobile(max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include tablet(min) {
    column-gap: var(--awTabCarouselSlideGroupGap, 24px);
  }

  @include desktop-small(min) {
    column-gap: var(--awTabCarouselSlideGroupGap, 32px);
  }

  @include desktop-medium(min) {
    column-gap: var(--awTabCarouselSlideGroupGap, 40px);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.fallbackSlide {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 348px;
  padding: 40px;
  text-align: center;
  color: $color-text-secondary;
  border: 1px dashed $color-dash-separator;
  border-radius: 24px;
  gap: 24px;

  @include tablet(min) {
    font-size: 16px;
    min-height: 296px;
  }
}

.arrowIcon {
  vertical-align: unset;
}

.mainTitleMargined {
  margin-bottom: 16px !important;
}

.tooltipIcon {
  margin-top: 8px;
  color: $color-plum-500;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.awSrOnly {
  position: absolute !important;
}
</style>
